import type { Language } from '~/types/language'

export const authTokenLocalStorageKey = 'groover-auth-code' as const

export type SsoProvider = 'google' | 'facebook' | 'soundcloud'
export type ProfileType = 'artist' | 'artist_repr' | 'curator'

/**
 * The authentication service representation of a user.
 * This is not a core user!
 */
export type AuthUser = {
  accepted_tos: boolean
  allowed_services: ['core']
  email: string
  first_name: string
  lang: Language
  last_name: string
  profile_type: string
  social_accounts: {
    provider: SsoProvider
    uid: string
  }[]
  /**
   * Authentification service unique id bound to the user.
   */
  uid: string
}

export type AuthToken = {
  access: string
  refresh: string
}

export type AuthLogin = {
  user: AuthUser
} & AuthToken
